import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Eye,
  EyeOff,
  Mail,
  Lock,
  User,
  Phone,
  Scissors,
  CheckCircle,
} from 'lucide-react';

const SignupPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const features = [
    'Access to expert tailors',
    'Track your orders in real-time',
    'Schedule appointments at your convenience',
    'Personalized measurements profile',
    'Exclusive offers and updates'
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords don't match");
      return;
    }
    setIsLoading(true);
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1500));
    setIsLoading(false);
    navigate('/dashboard');
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="min-h-screen relative overflow-hidden bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      {/* Background Decorations */}
      <div className="absolute inset-0">
        <div className="absolute top-0 right-0 w-1/2 h-full bg-[#800020]/5 clip-path-diagonal" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-[#800020]/5 rounded-full blur-3xl transform -translate-x-1/2 translate-y-1/2" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-[#800020]/5 rounded-full blur-3xl transform translate-x-1/2 -translate-y-1/2" />
      </div>

      <div className="min-h-screen flex flex-col lg:flex-row">
        {/* Left Panel - Benefits */}
        <div className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-[#800020] to-[#4A0012] p-12 relative overflow-hidden">
          <div className="relative z-10 flex flex-col justify-center text-white">
            <div className="flex items-center gap-3 mb-12">
              <div className="w-12 h-12 bg-white/10 rounded-xl flex items-center justify-center">
                <Scissors className="h-6 w-6 text-white" />
              </div>
              <h1 className="text-3xl font-bold">StitchKaro</h1>
            </div>
            
            <h2 className="text-4xl font-bold mb-8">Create your account</h2>
            <p className="text-xl text-white/80 mb-12">Join StitchKaro and experience premium tailoring services at your doorstep.</p>
            
            <div className="space-y-6">
              {features.map((feature, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center">
                    <CheckCircle className="h-5 w-5 text-white" />
                  </div>
                  <p className="text-lg text-white/90">{feature}</p>
                </div>
              ))}
            </div>

            {/* Decorative Elements */}
            <div className="absolute -bottom-24 -right-24 w-96 h-96 border border-white/10 rounded-full" />
            <div className="absolute -bottom-12 -right-12 w-64 h-64 border border-white/10 rounded-full" />
          </div>
        </div>

        {/* Right Panel - Sign Up Form */}
        <div className="flex-1 flex items-center justify-center p-6">
          <div className="w-full max-w-md space-y-8 bg-white p-8 rounded-2xl shadow-xl relative">
            <div className="text-center lg:hidden">
              <div className="w-16 h-16 bg-gradient-to-r from-[#800020] to-[#4A0012] rounded-2xl mx-auto flex items-center justify-center mb-4">
                <Scissors className="h-8 w-8 text-white" />
              </div>
              <h2 className="text-3xl font-bold text-[#800020]">Create Account</h2>
              <p className="mt-2 text-gray-600">Join StitchKaro today</p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Name Fields */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
                  <div className="relative">
                    <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="pl-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                      placeholder="John"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
                  <div className="relative">
                    <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="pl-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                      placeholder="Doe"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Email Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="pl-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                    placeholder="john@example.com"
                    required
                  />
                </div>
              </div>

              {/* Phone Field */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                <div className="relative">
                  <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="pl-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                    placeholder="+91 1234567890"
                    required
                  />
                </div>
              </div>

              {/* Password Fields */}
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Password</label>
                  <div className="relative">
                    <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="pl-10 pr-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                      placeholder="••••••••"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      {showPassword ? (
                        <EyeOff className="h-5 w-5 text-gray-400" />
                      ) : (
                        <Eye className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Confirm Password</label>
                  <div className="relative">
                    <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      className="pl-10 pr-10 block w-full rounded-xl border border-gray-200 bg-gray-50 py-3 px-4 focus:ring-2 focus:ring-[#800020] focus:border-[#800020] transition-all duration-200"
                      placeholder="••••••••"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      {showConfirmPassword ? (
                        <EyeOff className="h-5 w-5 text-gray-400" />
                      ) : (
                        <Eye className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-xl text-sm font-medium text-white bg-[#800020] hover:bg-[#4A0012] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#800020] transition-all duration-200 shadow-lg shadow-[#800020]/20"
              >
                {isLoading ? (
                  <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  'Create Account'
                )}
              </button>

              {/* Sign In Link */}
              <p className="text-center text-sm text-gray-600">
                Already have an account?{' '}
                <button
                  type="button"
                  onClick={() => navigate('/login')} // Ensure this is /login
                  className="font-medium text-[#800020] hover:text-[#4A0012]"
                >
                  Sign in
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>

      <style jsx>{`
        .clip-path-diagonal {
          clip-path: polygon(100% 0, 100% 100%, 0 100%);
        }
      `}</style>
    </div>
  );
};

export default SignupPage;
