import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import {
  Scissors,
  Clock,
  Truck,
  CheckCircle,
  MessageCircle,
  ArrowRight,
  Package,
} from 'lucide-react';

const statusStyles = {
  measuring: {
    text: 'Measurement Scheduled',
    icon: Scissors,
    color: 'text-[#7D0321]',
    bg: 'bg-[#7D0321]/50',
    border: 'border-[#7D0321]/200',
    progress: 25
  },
  in_progress: {
    text: 'In Progress',
    icon: Clock,
    color: 'text-[#7D0321]',
    bg: 'bg-[#7D0321]/50',
    border: 'border-[#7D0321]/200',
    progress: 50
  },
  delivery: {
    text: 'Out for Delivery',
    icon: Truck,
    color: 'text-[#7D0321]',
    bg: 'bg-[#7D0321]/50',
    border: 'border-[#7D0321]/200',
    progress: 75
  },
  completed: {
    text: 'Completed',
    icon: CheckCircle,
    color: 'text-[#7D0321]',
    bg: 'bg-[#7D0321]/50',
    border: 'border-[#7D0321]/200',
    progress: 100
  }
};

const orders = [
  {
    id: 'SK001',
    service: 'Suit Stitching',
    details: 'Three-piece wedding suit in navy blue',
    date: '2023-08-15',
    status: 'in_progress',
    price: '₹15,000',
    estimatedDelivery: '2023-08-25',
    tailor: {
      name: 'Raj Kumar',
      phone: '+91 98765 43210',
      rating: 4.8,
      totalOrders: 156
    }
  },
  {
    id: 'SK002',
    service: 'Shirt Stitching',
    details: 'White formal cotton shirt',
    date: '2023-08-10',
    status: 'completed',
    price: '₹2,500',
    estimatedDelivery: '2023-08-20',
    tailor: {
      name: 'Amit Shah',
      phone: '+91 98765 43211',
      rating: 4.9,
      totalOrders: 234
    }
  }
];

export default function Orders() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-red-50 via-white to-red-50">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Welcome Section */}
        <div className="bg-[#7D0321] rounded-2xl shadow-xl">
          <div className="px-8 py-12 sm:px-12">
            <h1 className="text-3xl sm:text-4xl font-bold text-white mb-4">
              My Orders
            </h1>
            <p className="text-xl text-white/90 max-w-2xl">
              Track and manage your orders all in one place.
            </p>
            <button
              onClick={() => navigate('/appointments')}
              className="mt-6 group inline-flex items-center px-6 py-3 bg-white text-[#7D0321] rounded-lg text-lg font-semibold shadow-md hover:shadow-lg transition-all duration-200 hover:bg-red-50"
            >
              Manage Appointments
              <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </div>

        {/* Orders List */}
        {orders.length > 0 ? (
          <div className="bg-white/80 rounded-xl shadow-md border border-[#7D0321]/10 p-8 mt-8">
            <h2 className="text-2xl font-bold bg-gradient-to-r from-[#7D0321] to-[#7D0321] bg-clip-text text-transparent mb-8">
              Orders
            </h2>
            <div className="space-y-6">
              {orders.map((order) => {
                const status = statusStyles[order.status];
                const StatusIcon = status.icon;
                return (
                  <div
                    key={order.id}
                    className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200"
                  >
                    {/* Progress Bar */}
                    <div className="h-1 bg-gray-100 rounded-t-xl">
                      <div
                        className="h-1 bg-[#7D0321] rounded-t-xl transition-all duration-500"
                        style={{ width: `${status.progress}%` }}
                      />
                    </div>
                    <div className="p-6">
                      {/* Header */}
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3">
                          <Package className="h-8 w-8 text-[#7D0321]" />
                          <div>
                            <h2 className="text-lg font-semibold text-gray-900">Order #{order.id}</h2>
                            <p className="text-sm text-gray-500">{order.details}</p>
                          </div>
                        </div>
                        <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${status.bg} ${status.color} ${status.border} border`}>
                          <StatusIcon className="mr-1.5 h-4 w-4" />
                          {status.text}
                        </span>
                      </div>
                      {/* Details Grid */}
                      <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-3">
                        <div className="flex flex-col">
                          <dt className="text-sm font-medium text-gray-500">Service</dt>
                          <dd className="mt-1 text-sm font-semibold text-gray-900">{order.service}</dd>
                        </div>
                        <div className="flex flex-col">
                          <dt className="text-sm font-medium text-gray-500">Estimated Delivery</dt>
                          <dd className="mt-1 text-sm font-semibold text-gray-900">
                            {new Date(order.estimatedDelivery).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })}
                          </dd>
                        </div>
                        <div className="flex flex-col">
                          <dt className="text-sm font-medium text-gray-500">Total Amount</dt>
                          <dd className="mt-1 text-sm font-semibold text-gray-900">{order.price}</dd>
                        </div>
                      </div>
                      {/* Actions */}
                      <div className="mt-6 flex items-center justify-end pt-6 border-t border-gray-100">
                        <div className="flex items-center space-x-3">
                          <button className="inline-flex items-center px-3 py-1.5 border border-[#7D0321] text-sm font-medium rounded-lg text-[#7D0321] hover:bg-[#7D0321]/10 transition-colors">
                            <MessageCircle className="h-4 w-4 mr-1.5" />
                            Chat
                          </button>
                          <button
                            onClick={() => navigate(`/orders/${order.id}`)}
                            className="inline-flex items-center px-3 py-1.5 bg-[#7D0321] text-sm font-medium rounded-lg text-white hover:bg-[#5D0219] transition-colors"
                          >
                            Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="bg-white/80 rounded-xl shadow-md p-12 text-center border border-[#7D0321]/10 mt-8">
            <div className="mx-auto w-16 h-16 rounded-full bg-[#7D0321]/10 flex items-center justify-center">
              <Package className="h-8 w-8 text-[#7D0321]" />
            </div>
            <h3 className="mt-4 text-lg font-medium text-gray-900">No orders yet</h3>
            <p className="mt-2 text-sm text-gray-500 max-w-sm mx-auto">
              Get started by scheduling an appointment with our expert tailors for your custom clothing needs.
            </p>
            <div className="mt-6">
              <button
                onClick={() => navigate('/appointments')}
                className="inline-flex items-center px-4 py-2 bg-[#7D0321] text-white rounded-lg hover:bg-[#5D0219] transition-colors"
              >
                <Scissors className="h-4 w-4 mr-2" />
                Book Appointment
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}