import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { Search, MessageSquare, Heart, ChevronRight, Send } from 'lucide-react';

// Messages Page
export const MessagesPage = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [newMessage, setNewMessage] = useState('');

  const chats = [
    {
      id: 1,
      name: 'Tailor Support',
      lastMessage: 'Your measurements have been updated',
      time: '10:30 AM',
      unread: 2,
    },
    {
      id: 2,
      name: 'Order Updates',
      lastMessage: 'Your order #1234 is ready for pickup',
      time: '9:15 AM',
      unread: 0,
    },
  ];

  const messages = [
    {
      id: 1,
      senderId: 'user',
      message: 'Hi, I have a question about my order',
      time: '10:25 AM',
    },
    {
      id: 2,
      senderId: 'tailor',
      message: 'Hello! How can I help you today?',
      time: '10:30 AM',
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg border border-[#800020]/10 overflow-hidden">
          <div className="flex h-[calc(100vh-200px)]">
            {/* Chat List */}
            <div className="w-full sm:w-80 border-r border-[#800020]/10">
              <div className="p-4 border-b border-[#800020]/10">
                <h1 className="text-2xl font-bold text-gray-900">Messages</h1>
              </div>
              <div className="overflow-y-auto h-[calc(100%-65px)]">
                {chats.map((chat) => (
                  <button
                    key={chat.id}
                    onClick={() => setSelectedChat(chat)}
                    className={`w-full p-4 flex items-start space-x-3 hover:bg-[#800020]/5 transition-colors ${
                      selectedChat?.id === chat.id ? 'bg-[#800020]/5' : ''
                    }`}
                  >
                    <div className="flex-shrink-0">
                      <MessageSquare className="h-10 w-10 text-[#800020]" />
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="flex justify-between">
                        <p className="text-sm font-medium text-gray-900">{chat.name}</p>
                        <p className="text-xs text-gray-500">{chat.time}</p>
                      </div>
                      <p className="text-sm text-gray-500 truncate">{chat.lastMessage}</p>
                    </div>
                    {chat.unread > 0 && (
                      <span className="inline-flex items-center justify-center w-5 h-5 text-xs font-medium text-white bg-[#800020] rounded-full">
                        {chat.unread}
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>

            {/* Chat Area */}
            {selectedChat ? (
              <div className="flex-1 flex flex-col">
                {/* Chat Header */}
                <div className="p-4 border-b border-[#800020]/10 flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <MessageSquare className="h-8 w-8 text-[#800020]" />
                    <h2 className="text-lg font-semibold text-gray-900">{selectedChat.name}</h2>
                  </div>
                </div>

                {/* Messages */}
                <div className="flex-1 overflow-y-auto p-4 space-y-4">
                  {messages.map((msg) => (
                    <div
                      key={msg.id}
                      className={`flex ${msg.senderId === 'user' ? 'justify-end' : 'justify-start'}`}
                    >
                      <div
                        className={`max-w-sm rounded-lg px-4 py-2 ${
                          msg.senderId === 'user'
                            ? 'bg-[#800020] text-white'
                            : 'bg-gray-100 text-gray-900'
                        }`}
                      >
                        <p className="text-sm">{msg.message}</p>
                        <p className="text-xs mt-1 opacity-70">{msg.time}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Input Area */}
                <div className="p-4 border-t border-[#800020]/10">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      // Handle message send
                    }}
                    className="flex space-x-2"
                  >
                    <input
                      type="text"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      className="flex-1 rounded-lg border border-gray-300 px-4 py-2 focus:border-[#800020] focus:ring-[#800020]"
                      placeholder="Type your message..."
                    />
                    <button
                      type="submit"
                      className="px-4 py-2 bg-[#800020] text-white rounded-lg hover:bg-[#4A0012] transition-colors"
                    >
                      <Send className="h-5 w-5" />
                    </button>
                  </form>
                </div>
              </div>
            ) : (
              <div className="flex-1 flex items-center justify-center">
                <div className="text-center">
                  <MessageSquare className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                  <p className="text-gray-500">Select a conversation to start messaging</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
