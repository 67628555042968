import React, { useState } from 'react';
import {
  Clock,
  CheckCircle,
  ChevronDown
} from 'lucide-react';
import Navbar from '../components/Navbar';

const Services = () => {
  const [activeCategory, setActiveCategory] = useState('all');
  const [expandedFaq, setExpandedFaq] = useState(null);

  const categories = [
    { id: 'all', name: 'All Services' },
    { id: 'mens', name: "Men's Wear" },
    { id: 'womens', name: "Women's Wear" },
    { id: 'alterations', name: 'Alterations' },
  ];

  const services = [
    {
      id: 1,
      name: 'Custom Suit Stitching',
      category: 'mens',
      price: '₹4999',
      timeframe: '7-10 days',
      description: 'Professional suit stitching with premium fabric options and perfect fit guarantee.',
      features: ['Personal measurements', 'Multiple fittings', 'Premium materials', 'Custom styling'],
      image: '/api/placeholder/400/300'
    },
    {
      id: 2,
      name: 'Designer Blouse Stitching',
      category: 'womens',
      price: '₹1499',
      timeframe: '3-5 days',
      description: 'Expert blouse stitching with intricate designs and perfect fitting.',
      features: ['Designer patterns', 'Perfect fit guarantee', 'Multiple style options', 'Premium finish'],
      image: '/api/placeholder/400/300'
    },
    {
      id: 3,
      name: 'Kurta/Kurti Stitching',
      category: 'womens',
      price: '₹999',
      timeframe: '4-6 days',
      description: 'Custom kurta/kurti stitching with modern and traditional designs.',
      features: ['Custom design', 'Size adjustments', 'Multiple styles', 'Quality fabric'],
      image: '/api/placeholder/400/300'
    },
    // Add more services as needed
  ];

  const faqs = [
    {
      question: 'How does the measurement process work?',
      answer: 'Our experienced tailors will visit your location at the scheduled time. They use professional measurement techniques and digital tools to ensure accuracy. All measurements are stored securely for future reference.'
    },
    {
      question: 'What is your alteration policy?',
      answer: 'We offer free alterations within 7 days of delivery if the garment doesn\'t meet your expectations. Our goal is your complete satisfaction with the fit and finish.'
    },
    {
      question: 'Do you provide fabric consultation?',
      answer: 'Yes, our expert tailors can guide you through fabric selection based on the occasion, weather, and your preferences. We can also work with fabric you provide.'
    },
  ];

  const filteredServices = activeCategory === 'all' 
    ? services 
    : services.filter(service => service.category === activeCategory);

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="relative overflow-hidden bg-gradient-to-r from-[#800020] to-[#4A0012] rounded-2xl shadow-xl mb-12">
          <div className="absolute inset-0 opacity-20">
            <div className="absolute inset-0" style={{
              backgroundImage: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjAgMjBMNDAgMjBNMjAgMjBMMjAgNDBNMjAgMjBMMCAyME0yMCAyMEwyMCAwIiBzdHJva2U9InJnYmEoMjU1LCAyNTUsIDI1NSwgMC4yKSIgc3Ryb2tlLXdpZHRoPSIwLjUiLz48L3N2Zz4=')",
              backgroundSize: "40px 40px"
            }} />
          </div>
          <div className="relative px-8 py-16 sm:px-12 text-center">
            <h1 className="text-4xl sm:text-5xl font-bold text-white mb-4">
              Premium Tailoring Services
            </h1>
            <p className="text-xl text-white/90 max-w-2xl mx-auto">
              Experience the perfect fit with our expert tailoring services. Professional craftsmanship at your doorstep.
            </p>
          </div>
        </div>

        {/* Category Filter */}
        <div className="flex flex-wrap gap-2 mb-8">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setActiveCategory(category.id)}
              className={`px-4 py-2 rounded-xl text-sm font-medium transition-all duration-200 ${
                activeCategory === category.id
                  ? 'bg-[#800020] text-white'
                  : 'bg-white text-gray-700 hover:bg-[#800020]/5 hover:text-[#800020]'
              }`}
            >
              {category.name}
            </button>
          ))}
        </div>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
          {filteredServices.map((service) => (
            <div key={service.id} className="bg-white rounded-xl shadow-lg overflow-hidden border border-[#800020]/10 group hover:shadow-xl transition-all duration-200">
              <div className="relative h-48 overflow-hidden">
                <img
                  src={service.image}
                  alt={service.name}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-200"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                <div className="absolute bottom-4 left-4 right-4">
                  <h3 className="text-xl font-bold text-white">{service.name}</h3>
                  <p className="text-white/90 flex items-center">
                    <Clock className="h-4 w-4 mr-1" />
                    {service.timeframe}
                  </p>
                </div>
              </div>
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <p className="text-2xl font-bold text-[#800020]">{service.price}</p>
                  <button className="px-4 py-2 bg-[#800020] text-white rounded-lg text-sm font-medium hover:bg-[#4A0012] transition-colors duration-200">
                    Book Now
                  </button>
                </div>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, index) => (
                    <li key={index} className="flex items-center text-sm text-gray-600">
                      <CheckCircle className="h-4 w-4 mr-2 text-[#800020]" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* FAQs Section */}
        <div className="bg-white rounded-xl shadow-lg p-8 border border-[#800020]/10">
          <h2 className="text-3xl font-bold text-[#800020] mb-8">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-[#800020]/10 last:border-0 pb-4 last:pb-0">
                <button
                  onClick={() => setExpandedFaq(expandedFaq === index ? null : index)}
                  className="w-full flex justify-between items-center py-2 text-left"
                >
                  <span className="font-medium text-gray-900">{faq.question}</span>
                  <ChevronDown className={`h-5 w-5 text-[#800020] transition-transform duration-200 ${
                    expandedFaq === index ? 'rotate-180' : ''
                  }`} />
                </button>
                {expandedFaq === index && (
                  <p className="mt-2 text-gray-600 pl-4">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
