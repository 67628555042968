import React, { useState } from 'react';
import {
  User,
  Edit2,
} from 'lucide-react';
import { useEnhancePages } from '../components/Modals';
import { AddCardModal } from '../components/AddCardModal';
import { DeleteAccountModal } from '../components/DeleteAccountModal';
import { UpdateMeasurementsModal } from '../components/UpdateMeasurementsModal';
import { Toast } from '../components/Toast';
import Navbar from '../components/Navbar';

// Personal Information Page
export const PersonalInformation = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    firstName: 'John',
    lastName: 'Doe',
    email: 'john@example.com',
    phone: '+91 9876543210',
    address: '123 Main Street, Mumbai, India',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsEditing(false);
    // Handle form submission
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg p-6 border border-[#800020]/10">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-gray-900">Personal Information</h1>
            <button
              onClick={() => setIsEditing(!isEditing)}
              className="flex items-center px-4 py-2 text-sm font-medium text-[#800020] hover:bg-[#800020]/5 rounded-lg transition-colors"
            >
              <Edit2 className="h-4 w-4 mr-2" />
              {isEditing ? 'Cancel' : 'Edit'}
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  type="text"
                  disabled={!isEditing}
                  value={formData.firstName}
                  onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020] disabled:bg-gray-100"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                <input
                  type="text"
                  disabled={!isEditing}
                  value={formData.lastName}
                  onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020] disabled:bg-gray-100"
                />
              </div>
              <div className="sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  disabled={!isEditing}
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020] disabled:bg-gray-100"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="tel"
                  disabled={!isEditing}
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020] disabled:bg-gray-100"
                />
              </div>
              <div className="sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Address</label>
                <textarea
                  disabled={!isEditing}
                  value={formData.address}
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                  rows={3}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020] disabled:bg-gray-100"
                />
              </div>
            </div>

            {isEditing && (
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-[#800020] hover:bg-[#4A0012] rounded-lg transition-colors"
                >
                  Save Changes
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

// Privacy & Security Page
export const PrivacyAndSecurity = () => {
  const {
    showDeleteAccount,
    setShowDeleteAccount,
    showToast,
    toast,
    setToast
  } = useEnhancePages();

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-6">
          {/* Password Section */}
          <div className="bg-white rounded-xl shadow-lg p-6 border border-[#800020]/10">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Password</h2>
            <form className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Current Password</label>
                <input
                  type="password"
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020]"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">New Password</label>
                <input
                  type="password"
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020]"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Confirm New Password</label>
                <input
                  type="password"
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020]"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-[#800020] hover:bg-[#4A0012] rounded-lg transition-colors"
                >
                  Update Password
                </button>
              </div>
            </form>
          </div>

          {/* Two-Factor Authentication */}
          <div className="bg-white rounded-xl shadow-lg p-6 border border-[#800020]/10">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Two-Factor Authentication</h2>
            <p className="text-gray-600 mb-4">Add an extra layer of security to your account.</p>
            <button className="px-4 py-2 text-sm font-medium text-[#800020] border border-[#800020] hover:bg-[#800020]/5 rounded-lg transition-colors">
              Enable 2FA
            </button>
          </div>

          {/* Delete Account */}
          <div className="bg-white rounded-xl shadow-lg p-6 border border-red-200">
            <h2 className="text-xl font-bold text-red-600 mb-4">Delete Account</h2>
            <p className="text-gray-600 mb-4">
              Once you delete your account, there is no going back. Please be certain.
            </p>
            <button
              onClick={() => setShowDeleteAccount(true)}
              className="px-4 py-2 text-sm font-medium text-red-600 border border-red-600 hover:bg-red-50 rounded-lg transition-colors"
            >
              Delete Account
            </button>
          </div>
        </div>

        <DeleteAccountModal 
          isOpen={showDeleteAccount} 
          onClose={() => setShowDeleteAccount(false)} 
        />
      
        {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        )}
      </div>
    </div>
  );
};

// Payment Methods Page
export const PaymentMethods = () => {
  const {
    showAddCard,
    setShowAddCard,
    showToast,
    toast,
    setToast
  } = useEnhancePages();

  const [cards, setCards] = useState([
    { id: 1, last4: '4242', brand: 'visa', expMonth: 12, expYear: 2024 },
    { id: 2, last4: '5555', brand: 'mastercard', expMonth: 8, expYear: 2025 },
  ]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg p-6 border border-[#800020]/10">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-gray-900">Payment Methods</h1>
            <button 
              onClick={() => setShowAddCard(true)}
              className="flex items-center px-4 py-2 text-sm font-medium text-[#800020] hover:bg-[#800020]/5 rounded-lg transition-colors"
            >
              <Edit2 className="h-4 w-4 mr-2" />
              Add New Card
            </button>
          </div>

          <div className="space-y-4">
            {cards.map((card) => (
              <div key={card.id} className="flex items-center justify-between p-4 border border-gray-200 rounded-lg">
                <div className="flex items-center">
                  <div className="w-12 h-8 bg-gray-100 rounded flex items-center justify-center mr-4">
                    {card.brand === 'visa' ? 'Visa' : 'MC'}
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-900">
                      •••• {card.last4}
                    </p>
                    <p className="text-sm text-gray-500">
                      Expires {card.expMonth}/{card.expYear}
                    </p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button className="p-2 text-gray-400 hover:text-[#800020] rounded-lg transition-colors">
                    <Edit2 className="h-4 w-4" />
                  </button>
                  <button className="p-2 text-gray-400 hover:text-red-600 rounded-lg transition-colors">
                    <Edit2 className="h-4 w-4" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <AddCardModal 
          isOpen={showAddCard} 
          onClose={() => setShowAddCard(false)} 
        />
      
        {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        )}
      </div>
    </div>
  );
};

// My Profile Page
export const MyProfile = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg p-6 border border-[#800020]/10">
          <h1 className="text-2xl font-bold text-gray-900">My Profile</h1>
          {/* Add content for My Profile here */}
        </div>
      </div>
    </div>
  );
};
