import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';

export const DeleteAccountModal = ({ isOpen, onClose }) => {
  const [password, setPassword] = useState('');

  const handleDelete = (e) => {
    e.preventDefault();
    // Handle account deletion
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-md p-6">
        <div className="flex items-center justify-center mb-6">
          <div className="w-12 h-12 rounded-full bg-red-100 flex items-center justify-center">
            <AlertCircle className="h-6 w-6 text-red-600" />
          </div>
        </div>
        <h2 className="text-xl font-bold text-gray-900 text-center mb-2">Delete Account</h2>
        <p className="text-gray-500 text-center mb-6">
          This action cannot be undone. All your data will be permanently deleted.
        </p>
        <form onSubmit={handleDelete} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Confirm your password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-red-500 focus:ring-red-500"
              placeholder="Enter your password"
            />
          </div>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-lg transition-colors"
            >
              Delete Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
