import { useState } from 'react';
import Navbar from '../components/Navbar';
import AppointmentBooking from '../components/AppointmentBooking';
import {
  Calendar,
  Clock,
  MapPin,
  Scissors,
  User,
  Phone,
  MessageSquare,
  Package,
  ChevronRight,
  CalendarCheck
} from 'lucide-react';

export default function Appointments() {
  const [activeTab, setActiveTab] = useState('upcoming');
  const [isAppointmentBookingVisible, setAppointmentBookingVisible] = useState(false);
  
  const appointments = [
    {
      id: 'APT001',
      type: 'Suit Measurement',
      date: '2024-12-15',
      time: '10:00 AM',
      status: 'confirmed',
      location: 'Mumbai Store',
      address: '123 Fashion Street, Mumbai',
      tailor: {
        name: 'Raj Kumar',
        phone: '+91 98765 43210',
        specialization: 'Wedding Suits'
      },
      notes: 'Three-piece suit measurement for wedding'
    },
    {
      id: 'APT002',
      type: 'Shirt Fitting',
      date: '2024-12-20',
      time: '2:30 PM',
      status: 'pending',
      location: 'Delhi Store',
      address: '456 Style Avenue, Delhi',
      tailor: {
        name: 'Amit Shah',
        phone: '+91 98765 43211',
        specialization: 'Formal Shirts'
      },
      notes: 'Final fitting for white formal shirt'
    }
  ];

  const statusStyles = {
    confirmed: {
      text: 'Confirmed',
      bg: 'bg-green-50',
      color: 'text-green-700',
      border: 'border-green-200'
    },
    pending: {
      text: 'Pending',
      bg: 'bg-yellow-50',
      color: 'text-yellow-700',
      border: 'border-yellow-200'
    },
    completed: {
      text: 'Completed',
      bg: 'bg-gray-50',
      color: 'text-gray-700',
      border: 'border-gray-200'
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-8 py-8">
        {/* Header */}
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-semibold text-[#800020]">My Appointments</h1>
            <p className="mt-1 text-sm text-gray-500">Schedule and manage your appointments</p>
          </div>
          <button
            onClick={() => setAppointmentBookingVisible(true)}
            className="inline-flex items-center px-4 py-2 bg-[#800020] text-white rounded-lg hover:bg-[#4A0012] transition-colors duration-200"
          >
            <Calendar className="h-4 w-4 mr-2" />
            Book Appointment
          </button>
        </div>

        {/* Tabs */}
        <div className="border-b border-red-100">
          <nav className="-mb-px flex space-x-8">
            {['upcoming', 'past'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`
                  ${activeTab === tab
                    ? 'border-b-2 border-[#800020] text-[#800020]'
                    : 'border-b-2 border-transparent text-gray-500 hover:text-[#800020] hover:border-red-200'
                  }
                  whitespace-nowrap py-4 px-1 font-medium text-sm
                `}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)} Appointments
              </button>
            ))}
          </nav>
        </div>

        {/* Appointments List */}
        <div className="space-y-6">
          {appointments.length > 0 ? (
            appointments.map((appointment) => {
              const status = statusStyles[appointment.status];
              return (
                <div
                  key={appointment.id}
                  className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200 border border-[#800020]/10"
                >
                  <div className="p-6">
                    {/* Header */}
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <div className="h-10 w-10 rounded-full flex items-center justify-center bg-[#800020]">
                          <Scissors className="h-5 w-5 text-white" />
                        </div>
                        <div>
                          <h2 className="text-lg font-semibold text-gray-900">{appointment.type}</h2>
                          <p className="text-sm text-gray-500">Appointment #{appointment.id}</p>
                        </div>
                      </div>
                      <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${status.bg} ${status.color} ${status.border} border`}>
                        {status.text}
                      </span>
                    </div>

                    {/* Details Grid */}
                    <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-3">
                      <div className="flex items-start space-x-3">
                        <Calendar className="h-5 w-5 text-[#800020]" />
                        <div>
                          <dt className="text-sm font-medium text-gray-500">Date & Time</dt>
                          <dd className="mt-1 text-sm font-semibold text-gray-900">
                            {new Date(appointment.date).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })}
                            <br />
                            {appointment.time}
                          </dd>
                        </div>
                      </div>
                      <div className="flex items-start space-x-3">
                        <MapPin className="h-5 w-5 text-[#800020]" />
                        <div>
                          <dt className="text-sm font-medium text-gray-500">Location</dt>
                          <dd className="mt-1 text-sm font-semibold text-gray-900">
                            {appointment.location}
                            <br />
                            <span className="font-normal text-gray-500">{appointment.address}</span>
                          </dd>
                        </div>
                      </div>
                      <div className="flex items-start space-x-3">
                        <User className="h-5 w-5 text-[#800020]" />
                        <div>
                          <dt className="text-sm font-medium text-gray-500">Tailor</dt>
                          <dd className="mt-1 text-sm font-semibold text-gray-900">
                            {appointment.tailor.name}
                            <br />
                            <span className="font-normal text-gray-500">{appointment.tailor.specialization}</span>
                          </dd>
                        </div>
                      </div>
                    </div>

                    {/* Notes & Actions */}
                    <div className="mt-6 flex items-center justify-between pt-6 border-t border-red-100">
                      <div className="flex items-center space-x-3">
                        <MessageSquare className="h-5 w-5 text-[#800020]" />
                        <p className="text-sm text-gray-500">{appointment.notes}</p>
                      </div>
                      <div className="flex items-center space-x-3">
                        <button className="inline-flex items-center px-3 py-1.5 border border-[#800020] text-sm font-medium rounded-lg text-[#800020] hover:bg-red-50 transition-colors">
                          <Phone className="h-4 w-4 mr-1.5" />
                          Call Tailor
                        </button>
                        <button className="inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-lg text-white bg-[#800020] hover:bg-[#4A0012] transition-colors">
                          Reschedule
                          <ChevronRight className="h-4 w-4 ml-1.5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bg-white rounded-xl shadow-sm p-12 text-center border border-[#800020]/10">
              <div className="mx-auto w-16 h-16 rounded-full flex items-center justify-center bg-red-50">
                <CalendarCheck className="h-8 w-8 text-[#800020]" />
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-900">No appointments scheduled</h3>
              <p className="mt-2 text-sm text-gray-500 max-w-sm mx-auto">
                Book an appointment with our expert tailors for your custom clothing needs.
              </p>
              <div className="mt-6">
                <button
                  onClick={() => setAppointmentBookingVisible(true)}
                  className="inline-flex items-center px-4 py-2 bg-[#800020] text-white rounded-lg hover:bg-[#4A0012] transition-colors duration-200"
                >
                  <Calendar className="h-4 w-4 mr-2" />
                  Book Appointment
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Appointment Booking Modal */}
        {isAppointmentBookingVisible && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white rounded-xl shadow-2xl w-full max-w-3xl p-6 m-4">
              <button
                onClick={() => setAppointmentBookingVisible(false)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
              <AppointmentBooking />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
