import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import Appointments from './pages/appointments';
import { FavoritesPage } from './pages/FavoritesPage';
import { HelpCenterPage } from './pages/HelpCenterPage';
import { MessagesPage } from './pages/MessagesPage';
import LoginPage from './pages/Loginpage';
import SignupPage from './pages/signup';
import OrdersPage from './pages/orders';
import ServicesPage from './pages/services';
import { PersonalInformation, PrivacyAndSecurity, PaymentMethods } from './pages/SettingsandProfilePages';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/appointments" element={<Appointments />} />
        <Route path="/favorites" element={<FavoritesPage />} />
        <Route path="/help-center" element={<HelpCenterPage />} />
        <Route path="/messages" element={<MessagesPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/orders" element={<OrdersPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/profile" element={<PersonalInformation />} />
        <Route path="/privacy-and-security" element={<PrivacyAndSecurity />} />
        <Route path="/payment-methods" element={<PaymentMethods />} />
      </Routes>
    </Router>
  );
}

export default App;
