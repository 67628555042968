import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { Search, Heart } from 'lucide-react';

// Favorites Page
export const FavoritesPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);

  const categories = [
    {
      id: 1,
      title: 'Designs',
      items: [
        {
          id: 1,
          type: 'design',
          title: 'Classic Suit Design',
          description: 'Three-piece suit with modern cut',
          date: '2024-01-15',
          image: '/api/placeholder/300/200',
        },
        {
          id: 2,
          type: 'design',
          title: 'Modern Jacket Design',
          description: 'Two-piece jacket with classic fit',
          date: '2024-01-10',
          image: '/api/placeholder/300/200',
        },
      ],
    },
    {
      id: 2,
      title: 'Fabrics',
      items: [
        {
          id: 3,
          type: 'fabric',
          title: 'Premium Italian Wool',
          description: 'High-quality wool fabric in navy blue',
          date: '2024-01-15',
          image: '/api/placeholder/300/200',
        },
        {
          id: 4,
          type: 'fabric',
          title: 'Luxury Silk',
          description: 'Soft and luxurious silk fabric in black',
          date: '2024-01-10',
          image: '/api/placeholder/300/200',
        },
      ],
    },
  ];

  const filteredFavorites = categories.flatMap(category =>
    category.items.filter(item =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg border border-[#800020]/10 p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-gray-900">Favorites</h1>
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search favorites..."
                className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:border-[#800020] focus:ring-[#800020]"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
          </div>

          <div className="mb-6">
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.id)}
                className={`px-4 py-2 rounded-lg mr-2 ${
                  selectedCategory === category.id
                    ? 'bg-[#800020] text-white'
                    : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                }`}
              >
                {category.title}
              </button>
            ))}
            <button
              onClick={() => setSelectedCategory(null)}
              className={`px-4 py-2 rounded-lg ${
                selectedCategory === null
                  ? 'bg-[#800020] text-white'
                  : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
              }`}
            >
              All
            </button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredFavorites
              .filter(item => !selectedCategory || item.type === categories.find(c => c.id === selectedCategory).items.some(i => i.id === item.id))
              .map((item) => (
                <div
                  key={item.id}
                  className="border border-[#800020]/10 rounded-xl overflow-hidden hover:shadow-lg transition-shadow"
                >
                  <img src={item.image} alt={item.title} className="w-full h-48 object-cover" />
                  <div className="p-4">
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-xs font-medium text-[#800020] bg-[#800020]/10 px-2 py-1 rounded-full">
                        {item.type}
                      </span>
                      <button className="text-[#800020] hover:text-[#4A0012]">
                        <Heart className="h-5 w-5 fill-current" />
                      </button>
                    </div>
                    <h3 className="text-lg font-semibold text-gray-900">{item.title}</h3>
                    <p className="text-sm text-gray-500 mb-4">{item.description}</p>
                    <div className="flex justify-between items-center">
                      <span className="text-xs text-gray-500">Added {item.date}</span>
                      <button className="text-sm text-[#800020] hover:text-[#4A0012] font-medium">
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
