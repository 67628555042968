import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { Search, Star, HelpCircle, ChevronRight, ExternalLink, MessageSquare, Phone } from 'lucide-react';

// Help Center Page
export const HelpCenterPage = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const categories = [
    {
      title: 'Getting Started',
      icon: Star,
      articles: [
        'How to place your first order',
        'Understanding measurements',
        'Booking appointments',
      ],
    },
    {
      title: 'Orders & Tracking',
      icon: MessageSquare,
      articles: [
        'Track your order status',
        'Order modifications',
        'Cancellation policy',
      ],
    },
    {
      title: 'Account & Payments',
      icon: HelpCircle,
      articles: [
        'Managing your account',
        'Payment methods',
        'Refund process',
      ],
    },
  ];

  const contactMethods = [
    {
      title: 'Chat Support',
      description: 'Available 24/7',
      icon: MessageSquare,
      action: 'Start Chat',
    },
    {
      title: 'Phone Support',
      description: '+1 (555) 123-4567',
      icon: Phone,
      action: 'Call Now',
    },
  ];

  const filteredArticles = categories.flatMap(category =>
    category.articles.filter(article =>
      article.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FDF8F8] via-white to-[#F8EFEF]">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Search Section */}
        <div className="bg-gradient-to-r from-[#800020] to-[#4A0012] rounded-xl p-8 mb-8 text-center">
          <h1 className="text-3xl font-bold text-white mb-4">How can we help you?</h1>
          <div className="max-w-xl mx-auto relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for help..."
              className="w-full px-4 py-3 pl-12 rounded-lg focus:outline-none focus:ring-2 focus:ring-white"
            />
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          </div>
        </div>

        {/* Categories */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {categories.map((category) => (
            <div
              key={category.title}
              className="bg-white rounded-xl shadow-lg border border-[#800020]/10 p-6 hover:shadow-xl transition-shadow"
            >
              <category.icon className="h-8 w-8 text-[#800020] mb-4" />
              <h2 className="text-xl font-bold text-gray-900 mb-4">{category.title}</h2>
              <ul className="space-y-3">
                {filteredArticles
                  .filter(article => category.articles.includes(article))
                  .map((article, index) => (
                    <li key={index}>
                      <button className="flex items-center text-left w-full text-gray-600 hover:text-[#800020]">
                        <ChevronRight className="h-4 w-4 mr-2 flex-shrink-0" />
                        <span>{article}</span>
                      </button>
                    </li>
                  ))}
              </ul>
              <button className="mt-4 text-sm font-medium text-[#800020] hover:text-[#4A0012] flex items-center">
                View All
                <ExternalLink className="h-4 w-4 ml-1" />
              </button>
            </div>
          ))}
        </div>

        {/* Contact Methods */}
        <div className="bg-white rounded-xl shadow-lg border border-[#800020]/10 p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-6">Contact Us</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {contactMethods.map((method) => (
              <div
                key={method.title}
                className="flex items-center p-4 border border-[#800020]/10 rounded-lg hover:bg-[#800020]/5 transition-colors"
              >
                <method.icon className="h-8 w-8 text-[#800020] mr-4" />
                <div className="flex-1">
                  <h3 className="text-lg font-semibold text-gray-900">{method.title}</h3>
                  <p className="text-sm text-gray-500">{method.description}</p>
                </div>
                <button className="px-4 py-2 text-sm font-medium text-[#800020] hover:bg-[#800020]/10 rounded-lg transition-colors">
                  {method.action}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
