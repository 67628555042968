import React, { useState } from 'react';
import {
  Calendar,
  Clock,
  MapPin,
  Scissors,
  User,
  ArrowRight,
  ArrowLeft,
  Check,
  Phone,
  Mail,
  CalendarCheck
} from 'lucide-react';

export default function AppointmentBooking() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    service: '',
    date: '',
    time: '',
    location: '',
    tailor: '',
    name: '',
    phone: '',
    email: '',
    notes: ''
  });

  const services = [
    { id: 'suit', name: 'Suit Stitching', duration: '45 mins', price: '₹1,000' },
    { id: 'shirt', name: 'Shirt Stitching', duration: '30 mins', price: '₹500' },
    { id: 'alterations', name: 'Alterations', duration: '30 mins', price: '₹300' },
    { id: 'design', name: 'Design Consultation', duration: '60 mins', price: '₹1,500' }
  ];

  const locations = [
    { id: 'mumbai', name: 'Mumbai Store', address: '123 Fashion Street, Mumbai' },
    { id: 'delhi', name: 'Delhi Store', address: '456 Style Avenue, Delhi' }
  ];

  const tailors = [
    {
      id: 'tailor1',
      name: 'Raj Kumar',
      specialization: 'Wedding Suits',
      experience: '15 years',
      rating: 4.8
    },
    {
      id: 'tailor2',
      name: 'Amit Shah',
      specialization: 'Formal Shirts',
      experience: '12 years',
      rating: 4.9
    }
  ];

  const timeSlots = [
    '09:00 AM', '10:00 AM', '11:00 AM',
    '12:00 PM', '02:00 PM', '03:00 PM',
    '04:00 PM', '05:00 PM'
  ];

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleNext = () => {
    setStep(prev => prev + 1);
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
  };

  const handleSubmit = () => {
    // Here you would typically submit the form data to your backend
    console.log('Appointment booked:', formData);
    setStep(5); // Show success step
  };

  const renderProgressBar = () => (
    <div className="mb-8">
      <div className="flex items-center justify-between">
        {[1, 2, 3, 4].map((index) => (
          <div key={index} className="flex items-center">
            <div className={`
              w-8 h-8 rounded-full flex items-center justify-center
              ${step >= index ? 'bg-[#73001C] text-white' : 'bg-gray-100 text-gray-400'}
            `}>
              {step > index ? <Check className="h-5 w-5" /> : index}
            </div>
            {index < 4 && (
              <div className={`
                w-24 h-1 mx-2
                ${step > index ? 'bg-[#73001C]' : 'bg-gray-100'}
              `} />
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-between mt-2">
        <span className="text-sm">Service</span>
        <span className="text-sm">Date & Time</span>
        <span className="text-sm">Location</span>
        <span className="text-sm">Details</span>
      </div>
    </div>
  );

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold">Select a Service</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {services.map((service) => (
                <div
                  key={service.id}
                  onClick={() => handleInputChange('service', service.id)}
                  className={`
                    p-4 rounded-lg border-2 cursor-pointer
                    ${formData.service === service.id 
                      ? 'border-[#73001C] bg-red-50' 
                      : 'border-gray-200 hover:border-gray-300'
                    }
                  `}
                >
                  <div className="flex items-start justify-between">
                    <div>
                      <h3 className="font-medium">{service.name}</h3>
                      <p className="text-sm text-gray-500">Duration: {service.duration}</p>
                    </div>
                    <span className="text-[#73001C] font-medium">{service.price}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold">Select Date & Time</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Date
                </label>
                <input
                  type="date"
                  value={formData.date}
                  onChange={(e) => handleInputChange('date', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-[#73001C] focus:border-[#73001C]"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Time
                </label>
                <div className="grid grid-cols-2 gap-2">
                  {timeSlots.map((slot) => (
                    <button
                      key={slot}
                      onClick={() => handleInputChange('time', slot)}
                      className={`
                        px-4 py-2 text-sm rounded-lg border
                        ${formData.time === slot
                          ? 'border-[#73001C] bg-red-50 text-[#73001C]'
                          : 'border-gray-200 hover:border-gray-300'
                        }
                      `}
                    >
                      {slot}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold">Select Location & Tailor</h2>
            <div className="grid grid-cols-1 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Store Location
                </label>
                <div className="space-y-3">
                  {locations.map((location) => (
                    <div
                      key={location.id}
                      onClick={() => handleInputChange('location', location.id)}
                      className={`
                        p-4 rounded-lg border-2 cursor-pointer
                        ${formData.location === location.id
                          ? 'border-[#73001C] bg-red-50'
                          : 'border-gray-200 hover:border-gray-300'
                        }
                      `}
                    >
                      <div className="flex items-start space-x-3">
                        <MapPin className="h-5 w-5 text-gray-400" />
                        <div>
                          <h3 className="font-medium">{location.name}</h3>
                          <p className="text-sm text-gray-500">{location.address}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Tailor
                </label>
                <div className="space-y-3">
                  {tailors.map((tailor) => (
                    <div
                      key={tailor.id}
                      onClick={() => handleInputChange('tailor', tailor.id)}
                      className={`
                        p-4 rounded-lg border-2 cursor-pointer
                        ${formData.tailor === tailor.id
                          ? 'border-[#73001C] bg-red-50'
                          : 'border-gray-200 hover:border-gray-300'
                        }
                      `}
                    >
                      <div className="flex items-start justify-between">
                        <div>
                          <h3 className="font-medium">{tailor.name}</h3>
                          <p className="text-sm text-gray-500">{tailor.specialization}</p>
                          <p className="text-sm text-gray-500">Experience: {tailor.experience}</p>
                        </div>
                        <span className="text-sm text-[#73001C] font-medium">
                          ★ {tailor.rating}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      case 4:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold">Your Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Full Name
                </label>
                <div className="relative">
                  <User className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-[#73001C] focus:border-[#73001C]"
                    placeholder="John Doe"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Phone Number
                </label>
                <div className="relative">
                  <Phone className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                  <input
                    type="tel"
                    value={formData.phone}
                    onChange={(e) => handleInputChange('phone', e.target.value)}
                    className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-[#73001C] focus:border-[#73001C]"
                    placeholder="+91 98765 43210"
                  />
                </div>
              </div>

              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Email Address
                </label>
                <div className="relative">
                  <Mail className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => handleInputChange('email', e.target.value)}
                    className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-[#73001C] focus:border-[#73001C]"
                    placeholder="john@example.com"
                  />
                </div>
              </div>

              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Additional Notes
                </label>
                <textarea
                  value={formData.notes}
                  onChange={(e) => handleInputChange('notes', e.target.value)}
                  rows={4}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-[#73001C] focus:border-[#73001C]"
                  placeholder="Any specific requirements or preferences..."
                />
              </div>
            </div>
          </div>
        );

      case 5:
        return (
          <div className="text-center py-8">
            <div className="mx-auto w-16 h-16 rounded-full bg-green-100 flex items-center justify-center mb-4">
              <Check className="h-8 w-8 text-green-600" />
            </div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">
              Appointment Confirmed!
            </h2>
            <p className="text-gray-500 mb-6">
              We've sent the confirmation details to your email.
            </p>
            <button
              onClick={() => window.location.href = '/appointments'}
              className="inline-flex items-center px-4 py-2 bg-[#73001C] text-white rounded-lg hover:opacity-90 transition-opacity"
            >
              View My Appointments
              <ArrowRight className="ml-2 h-4 w-4" />
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="max-w-3xl mx-auto py-8 px-4">
      {step < 5 && renderProgressBar()}
      
      {renderStepContent()}

      {step < 5 && (
        <div className="mt-8 flex justify-between">
          {step > 1 ? (
            <button
              onClick={handleBack}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              Back
            </button>
          ) : (
            <div /> // Empty div for spacing
          )}
          
          <button
            onClick={step === 4 ? handleSubmit : handleNext}
            className="inline-flex items-center px-4 py-2 bg-[#73001C] text-white rounded-lg hover:opacity-90 transition-opacity"
          >
            {step === 4 ? 'Confirm Booking' : 'Continue'}
            <ArrowRight className="ml-2 h-4 w-4" />
          </button>
        </div>
      )}
    </div>
  );
}
