import { useState } from 'react';

export const useEnhancePages = () => {
  const [showAddCard, setShowAddCard] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [showUpdateMeasurements, setShowUpdateMeasurements] = useState(false);
  const [toast, setToast] = useState(null);

  const showToast = (message, type = 'success') => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000);
  };

  return {
    showAddCard,
    setShowAddCard,
    showDeleteAccount,
    setShowDeleteAccount,
    showUpdateMeasurements,
    setShowUpdateMeasurements,
    showToast,
    toast,
    setToast,
  };
};

export const AddCardModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-md p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-6">Add New Card</h2>
        <form className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Card Number</label>
            <input
              type="text"
              className="block w-full rounded-lg border border-gray-300 pl-3 pr-12 py-2 focus:border-[#800020] focus:ring-[#800020]"
            />
          </div>
          <div className="flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700">Expiry Date</label>
              <input
                type="text"
                placeholder="MM/YY"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020]"
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700">CVC</label>
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-[#800020] focus:ring-[#800020]"
              />
            </div>
          </div>
          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-[#800020] hover:bg-[#4A0012] rounded-lg transition-colors"
            >
              Add Card
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const DeleteAccountModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-md p-6">
        <h2 className="text-xl font-bold text-red-600 mb-6">Delete Account</h2>
        <p className="text-gray-600 mb-6">
          Are you sure you want to delete your account? This action is irreversible.
        </p>
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-lg"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {
              // Handle account deletion
              onClose();
            }}
            className="px-4 py-2 text-sm font-medium text-red-600 border border-red-600 hover:bg-red-50 rounded-lg transition-colors"
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};
