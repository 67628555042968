import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Menu,
  X,
  ChevronLeft,
  ChevronRight,
  Home,
  Scissors,
  ShoppingBag,
  Calendar,
  Settings,
  Bell,
  User,
  LogOut,
  HelpCircle,
  MessageSquare,
  Clock,
  Heart,
  ChevronRight as ArrowIcon,
  Shield,
  UserCircle,
  CreditCard,
  Languages,
} from 'lucide-react';

console.log('Rendering Navbar component');

// Dropdown Component
const Dropdown = ({ isOpen, onClose, children }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div
      ref={dropdownRef}
      className="absolute right-0 mt-2 w-80 rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-50"
    >
      {children}
    </div>
  );
};

// NotificationItem Component
const NotificationItem = ({ icon: Icon, title, message, time, isUnread }) => (
  <div className={`p-4 hover:bg-[#800020]/5 cursor-pointer transition-colors ${isUnread ? 'bg-[#800020]/5' : ''}`}>
    <div className="flex gap-3">
      <div className={`flex-shrink-0 w-10 h-10 rounded-full flex items-center justify-center ${
        isUnread ? 'bg-[#800020]/10' : 'bg-gray-100'
      }`}>
        <Icon className={`h-5 w-5 ${isUnread ? 'text-[#800020]' : 'text-gray-500'}`} />
      </div>
      <div className="flex-1 min-w-0">
        <p className={`text-sm font-medium ${isUnread ? 'text-[#800020]' : 'text-gray-900'}`}>
          {title}
        </p>
        <p className="text-sm text-gray-500 truncate">{message}</p>
        <p className="text-xs text-gray-400 mt-1">{time}</p>
      </div>
    </div>
  </div>
);

// Main Navbar Component
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const navItems = [
    { name: 'Home', icon: Home, path: '/dashboard' },
    { name: 'Orders', icon: ShoppingBag, path: '/orders' },
    { name: 'Appointments', icon: Calendar, path: '/appointments' },
    { name: 'Services', icon: Scissors, path: '/services' }
  ];

  const notifications = [
    {
      id: 1,
      icon: Scissors,
      title: "Your order is ready",
      message: "Your tailored suit is ready for pickup",
      time: "5 minutes ago",
      isUnread: true
    },
    {
      id:2,
      icon: Calendar,
      title: "Appointment Reminder",
      message: "Your fitting session is tomorrow at 2 PM",
      time: "1 hour ago",
      isUnread: true
    },
    {
      id: 3,
      icon: Heart,
      title: "Special Offer",
      message: "20% off on your next order",
      time: "2 hours ago",
      isUnread: false
    },
  ];

  const settingsItems = [
    {
      title: "Account Settings",
      items: [
        { icon: UserCircle, label: "Personal Information", action: () => navigate('/profile') },
        { icon: Shield, label: "Privacy & Security", action: () => navigate('/privacy-and-security') },
        { icon: CreditCard, label: "Payment Methods", action: () => navigate('/payment-methods') },
      ]
    },
    {
      title: "Preferences",
      items: [
        { icon: Languages, label: "Language", action: () => navigate('/settings/language') },
        { icon: Bell, label: "Notifications", action: () => navigate('/settings/notifications') },
      ]
    }
  ];

  const handleSignOut = () => {
    // Clear authentication data from localStorage
    localStorage.removeItem('authToken');
    // Redirect to login page
    navigate('/login');
    return true; // Return statement indicating successful sign-out
  };

  const profileItems = [
    { icon: User, label: "My Profile", action: () => navigate('/profile') },
    { icon: ShoppingBag, label: "My Orders", action: () => navigate('/orders') },
    { icon: MessageSquare, label: "Messages", action: () => navigate('/messages') },
    { icon: Heart, label: "Favorites", action: () => navigate('/favorites') },
    { icon: HelpCircle, label: "Help Center", action: () => navigate('/help-center') },
    { icon: LogOut, label: "Sign Out", action: handleSignOut, className: 'text-red-600 hover:bg-red-50' }
  ];

  const goBack = () => {
    navigate(-1);
  };

  const goForward = () => {
    navigate(1);
  };

  return (
    <nav className="bg-white border-b border-[#800020]/10 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          {/* Left section */}
          <div className="flex items-center">
            {/* Logo */}
            <div className="flex-shrink-0 flex items-center">
              <div className="w-10 h-10 bg-gradient-to-r from-[#800020] to-[#4A0012] rounded-lg flex items-center justify-center">
                <Scissors className="h-6 w-6 text-white" />
              </div>
              <span className="ml-3 text-xl font-bold text-[#800020]">StitchKaro</span>
            </div>

            {/* Navigation Arrows */}
            <div className="hidden sm:flex sm:ml-6 space-x-2">
              <button
                onClick={goBack}
                className="p-2 rounded-lg hover:bg-[#800020]/5 text-gray-600 hover:text-[#800020] transition-colors"
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <button
                onClick={goForward}
                className="p-2 rounded-lg hover:bg-[#800020]/5 text-gray-600 hover:text-[#800020] transition-colors"
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden sm:ml-6 sm:flex sm:space-x-4">
              {navItems.map((item) => (
                <button
                  key={item.name}
                  onClick={() => navigate(item.path)}
                  className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                    location.pathname === item.path
                      ? 'bg-[#800020] text-white'
                      : 'text-gray-600 hover:bg-[#800020]/5 hover:text-[#800020]'
                  }`}
                >
                  <div className="flex items-center space-x-2">
                    <item.icon className="h-4 w-4" />
                    <span>{item.name}</span>
                  </div>
                </button>
              ))}
            </div>
          </div>

          {/* Right section */}
          <div className="flex items-center space-x-4">
            {/* Notifications */}
            <div className="relative">
              <button
                onClick={() => {
                  setIsNotificationsOpen(!isNotificationsOpen);
                  setIsSettingsOpen(false);
                  setIsProfileOpen(false);
                }}
                className="relative p-2 rounded-lg hover:bg-[#800020]/5 text-gray-600 hover:text-[#800020] transition-colors"
              >
                <Bell className="h-5 w-5" />
                {notifications.filter(n => n.isUnread).length > 0 && (
                  <span className="absolute top-0 right-0 -mt-1 -mr-1 px-2 py-0.5 text-xs font-medium text-white bg-[#800020] rounded-full">
                    {notifications.filter(n => n.isUnread).length}
                  </span>
                )}
              </button>
              
              <Dropdown isOpen={isNotificationsOpen} onClose={() => setIsNotificationsOpen(false)}>
                <div className="py-2">
                  <div className="px-4 py-2 border-b border-gray-100">
                    <h3 className="text-lg font-semibold text-gray-900">Notifications</h3>
                  </div>
                  <div className="max-h-96 overflow-y-auto">
                    {notifications.map((notification) => (
                      <NotificationItem key={notification.id} {...notification} />
                    ))}
                  </div>
                  <div className="px-4 py-2 border-t border-gray-100">
                    <button
                      onClick={() => navigate('/notifications')}
                      className="text-sm text-[#800020] hover:text-[#4A0012] font-medium flex items-center justify-center w-full"
                    >
                      View All
                      <ArrowIcon className="h-4 w-4 ml-1" />
                    </button>
                  </div>
                </div>
              </Dropdown>
            </div>

            {/* Settings */}
            <div className="relative">
              <button
                onClick={() => {
                  setIsSettingsOpen(!isSettingsOpen);
                  setIsNotificationsOpen(false);
                  setIsProfileOpen(false);
                }}
                className="p-2 rounded-lg hover:bg-[#800020]/5 text-gray-600 hover:text-[#800020] transition-colors"
              >
                <Settings className="h-5 w-5" />
              </button>
              
              <Dropdown isOpen={isSettingsOpen} onClose={() => setIsSettingsOpen(false)}>
                <div className="py-2">
                  {settingsItems.map((section, index) => (
                    <div key={section.title} className={index > 0 ? 'border-t border-gray-100' : ''}>
                      <div className="px-4 py-2">
                        <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider">
                          {section.title}
                        </h3>
                      </div>
                      {section.items.map((item) => (
                        <button
                          key={item.label}
                          onClick={item.action}
                          className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-[#800020]/5 hover:text-[#800020]"
                        >
                          <item.icon className="h-4 w-4 mr-3" />
                          {item.label}
                        </button>
                      ))}
                    </div>
                  ))}
                </div>
              </Dropdown>
            </div>

            {/* Profile */}
            <div className="relative">
              <button
                onClick={() => {
                  setIsProfileOpen(!isProfileOpen);
                  setIsNotificationsOpen(false);
                  setIsSettingsOpen(false);
                }}
                className="p-2 rounded-lg hover:bg-[#800020]/5 text-gray-600 hover:text-[#800020] transition-colors"
              >
                <User className="h-5 w-5" />
              </button>
              
              <Dropdown isOpen={isProfileOpen} onClose={() => setIsProfileOpen(false)}>
                <div className="py-2">
                  <div className="px-4 py-3 border-b border-gray-100">
                    <p className="text-sm font-medium text-gray-900">John Doe</p>
                    <p className="text-sm text-gray-500">john@example.com</p>
                  </div>
                  {profileItems.map((item) => (
                    <button
                      key={item.label}
                      onClick={item.action}
                      className={`flex items-center w-full px-4 py-2 text-sm ${
                        item.className || 'text-gray-700 hover:bg-[#800020]/5 hover:text-[#800020]'
                      }`}
                    >
                      <item.icon className="h-4 w-4 mr-3" />
                      <span>{item.label}</span>
                    </button>
                  ))}
                </div>
              </Dropdown>
            </div>

            {/* Mobile menu button */}
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="sm:hidden p-2 rounded-lg hover:bg-[#800020]/5 text-gray-600 hover:text-[#800020] transition-colors"
            >
              {isMobileMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="sm:hidden bg-white border-t border-[#800020]/10">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {navItems.map((item) => (
              <button
                key={item.name}
                onClick={() => {
                  navigate(item.path);
                  setIsMobileMenuOpen(false);
                }}
                className={`w-full px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                  location.pathname === item.path
                    ? 'bg-[#800020] text-white'
                    : 'text-gray-600 hover:bg-[#800020]/5 hover:text-[#800020]'
                }`}
              >
                <div className="flex items-center space-x-2">
                  <item.icon className="h-4 w-4" />
                  <span>{item.name}</span>
                </div>
              </button>
            ))}
          </div>
    
          {/* Mobile Menu Profile Items */}
          <div className="px-2 py-3 border-t border-[#800020]/10">
            {profileItems.map((item) => (
              <button
                key={item.label}
                onClick={() => {
                  item.action();
                  setIsMobileMenuOpen(false);
                }}
                className={`w-full px-3 py-2 rounded-lg text-sm font-medium flex items-center ${
                  item.className || 'text-gray-600 hover:bg-[#800020]/5 hover:text-[#800020]'
                }`}
              >
                <item.icon className="h-4 w-4 mr-3" />
                <span>{item.label}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
